<template>
  <div class="pics-list-cont">
    <div class="all-pics" v-for="(item, index) in imgUrls"
    :key="index">
      <my-image
        :src="item" 
        fit="fill"
        :preview-src-list="imgUrls">
      </my-image>
      <label v-show="showDel" class="list-del" @click="handleRemove(index)"><i class="el-icon-close"></i></label>
    </div>
    <div class="upload-progress" v-if="showProgress">
      <el-progress type="circle"
        :percentage="videoUploadPercent"
        class="progress-style">
      </el-progress>
    </div>
    <div class="drag-disabled" v-show="imgUrls.length < 1">
      <el-upload
        action
        :accept="'image/*'"
        :show-file-list="false"
        :http-request="uploadfiles"
        :limit="9"
        list-type="picture-card"
        class="pb10"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
export default {
  name: "pics",
  components: {
  },
  props: {
    dictCode: {
      type: String,
      default: "",
    },
    businessCode: {
      type: String,
      default: "",
    },
    propName: {
      type: String,
      default: '',
    },
    storeTableName: {
      type: String,
      default: '',
    },
    imgSize: {
      type: Number,
      default: 0.2
    },
    videoSize: {
      type: Number,
      default: 5
    },
    limit: {
      type: Number,
      default: 9
    },
    //是否裁剪
    showcrop: {
      type: Boolean,
      default: false
    },
    uploadUrl: {
      type: String,
      default: "uploadFiles",
    },
    showDel: {
      type: Boolean,
      default: true
    },
    imgUrls: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {   
      closeFlag: false,
      // imgUrls: [],
      tableName: '',
      videoUploadPercent: 0,
      showProgress: false,
      showCount: 1
    }
  },
  created() {
	},
  methods: {
    handleRemove(index) {
      this.imgUrls.splice(index, 1)
      this.picsSave()
    },
    async uploadfiles(param){
      if(this.imgUrls?.length >= 9){
        this.message('最多上传9张图片!', "warning")
        return
      }
      let formData = new FormData(),
        _this = this,
        uploadUrl = _this.uploadUrl,
        file = param.file, 
        size = file.size / (1024 * 1024)
      if(size > _this.imgSize){
        file = await _this.compressImg(file, size)
      }
      formData.append("dictCode", this.dictCode)
      formData.append("businessCode", this.businessCode)
      formData.append("files", file)
      _this.videoUploadPercent = 0
      _this.showProgress = true
      if(uploadUrl == 'uploadFiles2'){
        request.uploadFiles2(formData).then(res => {
          if(res && res.code == 200){
            let result = res.data || ''
            if(result && result.idCardNum){
              _this.$emit("getIdMes", {
                'fullName': res.data?.name || '',
                'idCard': res.data?.idCardNum || '',
              })
              _this.finalRequest(formData)
            }else{
              _this.message('没成功识别到身份证信息，请重新上传清晰的身份证！', "error")
            }
          }else{
            _this.message('上传失败，请稍后再试', "error")
          }
          _this.showProgress = false;
          _this.videoUploadPercent = 0
        }).catch(e => {
          _this.showProgress = false;
          _this.videoUploadPercent = 0
        })
      }else{
        _this.finalRequest(formData)
      }
        
    },
    finalRequest(formData){
      let _this = this
      request.uploadFiles(formData, _this.uploadVideoProcess).then(res => {
        if(res && res.code == 200){
          _this.imgUrls.push(res.data[0].fileUrl)
          _this.tableName = res.data[0].tableName
          _this.picsSave()
        }else if(res){
          _this.message(res.msg || '', "error")
        }else{
          _this.message('上传失败，请稍后再试', "error")
        }
        _this.showProgress = false;
        _this.videoUploadPercent = 0
      }).catch(e => {
        _this.showProgress = false;
        _this.videoUploadPercent = 0
      })
    },
    // base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
    dataURLtoFile (dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 图片压缩函数
    async compressImg (file) {
      const _this = this
      let files,
        fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2),
        read = new FileReader()
      read.readAsDataURL(file)
      return new Promise(function (resolve, reject) {
        read.onload = function (e) {
          let img = new Image()
          img.src = e.target.result
          img.onload = function () {
            // 默认按比例压缩
            let w = this.width,
              h = this.height,
              canvas = document.createElement('canvas'), // 生成canvas
              ctx = canvas.getContext('2d'),
              base64
            // 创建属性节点
            canvas.setAttribute('width', w)
            canvas.setAttribute('height', h)
            ctx.drawImage(this, 0, 0, w, h)
            if (fileSize < 1) {
              base64 = canvas.toDataURL(file['type'], 0.5)
            } else if (fileSize > 1 && fileSize < 2) {
              base64 = canvas.toDataURL(file['type'], 0.5)
            } else if(fileSize > 2 && fileSize < 5) {
              base64 = canvas.toDataURL(file['type'], 0.2)
            }else {
              base64 = canvas.toDataURL(file['type'], 0.1)
            }
            files = _this.dataURLtoFile(base64, file.name) 
            resolve(files)
          }
        }
      })
    },
    move(e){
      if(e.dragged._prevClass == 'drag-disabled') return false
      console.log(e)
    },
    picsSave(){
      let obj = {
        pics: [ ...this.imgUrls ],
        tableName: this.tableName,
        propName: this.propName,
        storeTableName: this.storeTableName
      }
      this.$emit("getpic", obj)
    },
    uploadVideoProcess(progressEvent) {   
      let per = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
      )
      if(per == 100){
        this.videoUploadPercent = 95
        setTimeout(() => {
          this.videoUploadPercent = 100
        }, 600);
      }else{

        this.videoUploadPercent = per
      }
    },
    closeModel() {
      this.closeFlag = false
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-upload ::v-deep .el-upload--picture-card{
    width: 88px;
    height: 88px;
    line-height: 98px;
  }
  .my-dialog1 ::v-deep .el-dialog {
    margin-top: 15vh !important;
  }
  .pics-list-cont{
    display: flex;
    flex-wrap: wrap;
  }
  .all-pics{
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
  .all-pics .el-image{
    width: 100%;
    height: 100%;
  }
  .list-del{
    position: absolute;
    right: -17px;
    top: -7px;
    width: 46px;
    height: 26px;
    background-color: red;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 1px 1px #ccc;
    cursor: pointer;
  }
  .list-del i{
    font-size: 12px;
    margin-top: 12px;
    transform: rotate(-45deg);
    color: #fff;
  }
  .my-dialog1 .all-pics:hover .list-shadow{
    opacity: 1
  }
  .my-dialog1 .all-pics:hover .list-shadow span{
    display: inline-flex;
  }
  .my-dialog1 .list-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0,0,0,.5);
    transition: opacity .3s;
    line-height: 148px;
  }
  .my-dialog1 .list-shadow span {
    display: none;
    cursor: pointer;
    margin: 10px;
  }
  .my-dialog1 .list-shadow .list-delete {
    position: static;
    font-size: inherit;
    color: inherit;
  }
  .upload-progress{
    width: 148px;
    height: 148px;
    background-color: #fff;
    border: 1px solid #c0ccda;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .pics-draggable{
    display: flex;
    flex-wrap: wrap;
  }
  .progress-style{
    margin-top: 7px
  }
</style>


