<template>
  <div class="my-bread-crumbs">
    <p class="breadcrumb-text">当前位置：</p>
    <p class="breadcrumb-content">
      <span class="breadbrumb-static" v-for="(item, index) in breadStaticData" :key="index + 'breadData'">
        {{item}}
        <i v-if='getArrow1(index)' class="el-breadcrumb__separator el-icon-arrow-right"></i>
      </span>
      <a class="breadbrumb-link"
        v-for="(item, index) in linkData" 
        :key="index + 'breadLink'" 
        @click="toLingk(item.path)">
        {{item.name}}
        <i v-if='getArrow2(index)' class="el-breadcrumb__separator el-icon-arrow-right"></i>
      </a>
      <span class="breadbrumb-static" v-for="(item, index) in thirdStaticData" :key="index + 'breadData'">
        {{item}}
        <i v-if='index < thirdStaticData.length - 1' class="el-breadcrumb__separator el-icon-arrow-right"></i>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'breadcrumb',
  props: {
    breadStaticData: {
      type: Array,
      default: () => []
    },
    linkData: {
      type: Array,
      default: () => []
    },
    thirdStaticData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toLingk(url){
      console.log(url);
      this.$router.push({
        path: url
      });
    },
    getArrow1(index){
      if(this.linkData.length || this.thirdStaticData.length){
        return index < this.breadStaticData.length ? true : false;
      }else{
        return index < this.breadStaticData.length - 1 ? true : false;
      }
    },
    getArrow2(index){
      if(this.thirdStaticData.length){
        return index < this.thirdStaticData.length ? true : false;
      }else{
        return index < this.thirdStaticData.length - 1 ? true : false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-bread-crumbs{
  display: flex;
  align-items: center;
  height: 40px;
  padding-top: 10px;
  .breadbrumb-static{
    color: #606266;
    font-size: 14px;
  }
  .breadcrumb-text{
    color: #606266;
    font-size: 14px;
    line-height: 16px;
    padding-right: 5px;
    padding-top: 3px;
  }
  .breadbrumb-link{
    font-size: 14px;
    color: #2064DE;
  }
}
</style>


