<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="content-cont">
        <div class="forms-btns-edit">
          <el-button type="primary" v-if="editType == 'look'" size="small" @click="edit">编辑</el-button>
        </div>
        <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            size="medium"
            label-width="160px"
          >
            <div> 
              <el-row>
                <el-col :span="24">
                  <el-form-item label="用户账号：" prop="userName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.userName }}</p>
                    <el-input clearable v-else v-model="ruleForm.userName" placeholder="请输入用户账号" :disabled="true"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="姓名：" prop="fullName">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.fullName }}</p>
                    <el-input clearable v-else :disabled="ruleForm.idCardUserImageUrl.length ? true : false" v-model="ruleForm.fullName" placeholder="请输入姓名" maxlength=16 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="手机号码：" prop="mobilePhoneNumber">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.mobilePhoneNumber }}</p>
                    <el-input clearable v-else v-model="ruleForm.mobilePhoneNumber" placeholder="请输入手机号码" maxlength=14 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="身份证号：" prop="idCard">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.idCard }}</p>
                    <el-input clearable v-else :disabled="ruleForm.idCardUserImageUrl.length ? true : false" v-model="ruleForm.idCard" placeholder="请输入身份证号" maxlength=18 show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="用户类型：" prop="userTypeName">
                    {{ ruleForm.userTypeName }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="" prop="phoneAuthCheck" label-width="90px">
                    <el-checkbox :disabled="editType == 'look'" v-model="ruleForm.phoneAuthCheck">是否手机认证</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.phoneAuthCheck">
                <el-col :span="24">
                  <el-form-item label="手机验证码：" prop="verificationCode">
                    <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.verificationCode }}</p>
                    <el-input v-else :class="sendFlag ? 'my-input-btn' :  'my-input-btn-gray'" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码">
                      <el-button slot="append" v-if="sendFlag" @click="sendMsg('mobilePhoneNumber')" type="primary">获取验证码</el-button>
                      <el-button slot="append" v-else class="btn-gray"  type="primary">{{countdown}}秒后重新发送</el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
                <p class="tips-gray">验证码10分钟有效</p>
              </el-row>
              <el-row v-if="ruleForm.phoneAuthCheck">
                <el-col :span="24">
                  <el-form-item label="身份证头像面图片：" prop="idCardUserImageUrl" class="my-upload-files">
                    <pic ref="idCardUserImageUrl" :imgUrls="ruleForm.idCardUserImageUrl" dictCode="102001001" :businessCode="ruleForm.userId" uploadUrl="uploadFiles2" :imgSize="0.5" propName="idCardUserImageUrl" storeTableName="idCardUploadTable" @getpic="getpic" @getIdMes="getIdMes"></pic>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.phoneAuthCheck">
                <el-col :span="24">
                  <el-form-item label="身份证国徽面图片：" prop="idCardNationalImageUrl" class="my-upload-files">
                    <pic ref="idCardNationalImageUrl" :imgUrls="ruleForm.idCardNationalImageUrl" dictCode="102001002" :businessCode="ruleForm.userId" :imgSize="0.5" propName="idCardNationalImageUrl" storeTableName="idCardUploadTable" @getpic="getpic"></pic>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="ruleForm.phoneAuthCheck">
                <el-col :span="24">
                  <el-form-item label="头像图片：" prop="headPortraitUrl" class="my-upload-files">
                    <pic ref="headPortraitUrl" :imgUrls="ruleForm.headPortraitUrl" dictCode="102001003" :businessCode="ruleForm.userId" :imgSize="0.5" propName="headPortraitUrl" storeTableName="idCardUploadTable" @getpic="getpic"></pic>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
        <div class="forms-btns">
          <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
          <el-button @click="closeModel" :disabled="loading" v-if="editType != 'look'" size="small"> 取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import breadcrumb from '@/components/breadcrumb.vue'
import pic from '@/views/common/pic.vue'
let _t = null
export default {
  name: "myInfo",
  components: {
    breadcrumb,
    pic
  },
  props:{
  },
  data() {
    return {   
      breadStaticData:['系统管理', '我的信息'],
      linkData:[],
      thirdStaticData:[],
      rules: {
        fullName: [{ required: true,  trigger: "change", message: '请输入姓名'}],
        headPortraitUrl: [{ required: true,  trigger: "change", message: '请上传头像图片'}],
        mobilePhoneNumber: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        verificationCode: [{ required: true,  trigger: "change", message: '请输入验证码'}]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: 'look',
      ruleForm: {
        userName: '',
        fullName: '',
        mobilePhoneNumber: '',
        idCard: '',
        phoneAuth: '',
        verificationCode: '',
        idCardUserImageUrl: '',
        idCardNationalImageUrl: '',
        headPortraitUrl: '',
        userType: '',
        phoneAuthCheck: false
      },
      initForm: {},
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
    };
  },
  created() {
    this.getInfo()
	},
  mounted() {
    _t = this
  },
  methods: {
    getIdMes(data){
      console.log(data)
      this.ruleForm.fullName = data.fullName || ''
      this.ruleForm.idCard = data.idCard || ''
    },
    getpic(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    edit(){
      this.editType = 'add'
    },
    getInfo(){
      let _this = this
      request.getmyInfo({
        userId: utils.getUserId()
      }).then(res => {
        if(res.code == 200){
          let result = res.data
          if(!result){
            _this.message('此条数据信息获取为空！', "error")
            return
          }
          let obj = {
            ...result,
            userTypeName: result.userType == '01' ? '管理员' : '普通',
            phoneAuthCheck: result.phoneAuth == '01' ? true : false,
            idCardUserImageUrl: result.idCardUserImageUrl ? [result.idCardUserImageUrl] : [],
            idCardNationalImageUrl: result.idCardNationalImageUrl ? [result.idCardNationalImageUrl] : [],
            headPortraitUrl: result.headPortraitUrl ? [result.headPortraitUrl] : [],
          }
          _this.ruleForm = obj
          _this.initForm = {
            ...obj,
            idCardUserImageUrl: [...obj.idCardUserImageUrl],
            idCardNationalImageUrl: [...obj.idCardNationalImageUrl],
            headPortraitUrl: [...obj.headPortraitUrl],
          }
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    sendMsg(prop){
      utils.sendMsg(this, prop, 'myInfo')
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitData()
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this,
        contObj = this.ruleForm

      _this.loading = true

      if(contObj.phoneAuthCheck){
        let flag = await utils.codeCheck('myInfo', contObj.mobilePhoneNumber, contObj.verificationCode) || false
        if(!flag) {
          _this.loading = false 
          return
        }
      }

      let obj = {
        ...contObj,
        phoneAuth: contObj.phoneAuthCheck ? '01' : '02',
        idCardUserImageUrl: contObj.idCardUserImageUrl.length ? contObj.idCardUserImageUrl.join(',') : '',
        idCardNationalImageUrl: contObj.idCardNationalImageUrl.length ? contObj.idCardNationalImageUrl.join(',') : '',
        headPortraitUrl: contObj.headPortraitUrl.length ? contObj.headPortraitUrl.join(',') : '',
      }
      
      request.updateMyInfo(obj).then((res) => {
        if(res.code == 200){
          _this.message('保存成功！', "success")
          _this.initForm = {
            ...contObj,
            idCardUserImageUrl: [...contObj.idCardUserImageUrl],
            idCardNationalImageUrl: [...contObj.idCardNationalImageUrl],
            headPortraitUrl: [...contObj.headPortraitUrl],
          }
          _this.editType = 'look'
        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })

    },
    closeModel() {
      this.ruleForm = {
        ...this.initForm,
        idCardUserImageUrl: [...this.initForm.idCardUserImageUrl],
        idCardNationalImageUrl: [...this.initForm.idCardNationalImageUrl],
        headPortraitUrl: [...this.initForm.headPortraitUrl],
      }
      this.resetForm()
      this.editType = 'look'
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .forms-btns-edit{
    text-align: right;
    padding-right: 10px;
    padding-bottom: 30px;
  }
  .forms-form{
    overflow: auto;
    height: calc(100% - 118px);
    padding-right: 40px;
  }
  .content-title{
    padding: 10px 0 40px;
    text-align: center;
    font-size: 20px;
  }
  .content-cont{
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 0 50px 20px 0;
    height: 100%;
    box-sizing: border-box;
  }
  .el-row{
    padding: 5px;
  }
  .tips-gray{
    margin-left: 55px;
  }
  .forms-btns {
    text-align: center;
    padding: 30px 20px 10px;
  }
</style>


